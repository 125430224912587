import {
  getLottieFromFile,
  LottieInformation,
} from "@bond-london/gatsby-graphcms-components";
import { LottieElement } from "@bond-london/gatsby-graphcms-components/dist/elements/LottieElement";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { File } from "../generated/graphql-types";

const lookupMap: { [key: string]: string } = {
  LightBlue: "dots-lottie-1",
  White: "dots-lottie-2",
  LightBlueLeftRight: "dots-lottie-3",
};

interface Props {
  type?: string;
  className?: string;
  inFront?: boolean;
  animation?: LottieInformation;
  translate?: string;
}

interface StaticData {
  allFile: {
    nodes: File[];
  };
}

const Dots: React.FC<Props> = ({
  type,
  className,
  inFront,
  animation,
  translate,
}) => {
  const {
    allFile: { nodes },
  } = useStaticQuery<StaticData>(graphql`
    query AnimationsQuery {
      allFile(filter: { sourceInstanceName: { eq: "animations" } }) {
        nodes {
          name
          publicURL
          lottie {
            encoded
          }
        }
      }
    }
  `);

  let lottie: LottieInformation;
  if (animation) {
    lottie = animation;
  } else {
    if (!type) {
      return null;
    }
    const possible = nodes.find((f) => f.name === lookupMap[type]);
    if (!possible) {
      throw new Error(`No animation for ${type}`);
    }

    const information = getLottieFromFile(possible);
    if (!information) {
      throw new Error("no animation for ${type}");
    }

    lottie = information;
  }

  return (
    <div
      data-component="Dots"
      className={classNames(className, "relative h-0", !inFront && "-z-1")}
    >
      <LottieElement
        alt="Dots"
        encoded={lottie.encoded}
        animationUrl={lottie.animationUrl}
        className={classNames(
          "aspect-w-1 aspect-h-1 transform-gpu",
          translate || "scale-150 -translate-y-6/12"
        )}
        loop={false}
      />
    </div>
  );
};

export default Dots;
